<template>
    <Header/>
    <Contact/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Contact from '@/components/contact.vue'

export default {
  components: {
     Header,
    Footer,
    Contact
  }
}
</script>