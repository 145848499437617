<template>
     <section id="contact" class="contact">
      <div class="container">

        <div class="row mt-1 p-4" >
          <div class="col-lg-6">
            <div class="info">
              <div class="address">
                <i class="lni lni-map-marker"></i>
                <h4>Location:</h4>
                <h6>Chennai</h6>
                <p>No. 30, Srinivasa Nagar 1st street, OFF OMR Road , Kandhanchavadi, Chennai - 600096.</p>
               <h6>Malaysia</h6>
               <p>No. 8, unit 32-01, level 32, Tower B the vertical corporate towers avenue 10 Bangsar South, Jalan Kerinchi 59200 kuala lumpur W.P. kuala lumpur MALAYSIA</p>
              <h6>US</h6>
               <p>4595, Chabot Drive, Suite 200, Pleasanton, California, - 94588.</p>
              </div>

              <div class="email">
                <i class="lni lni-envelope"></i>
                <h4>Email:</h4>
                <p>info@exceljuta.com</p>
              </div>

              <div class="phone">
                <i class="lni lni-phone"></i>
                <h4>Call:</h4>
                <p>044-4551 0181/ 82/ 83</p>
              </div>

            </div>

          </div>

          <div class="col-lg-6 mt-5 mt-lg-0 " id="exceljutacontact" style="padding-top:50px" >

            <form @submit.prevent="sendEmail" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="text-center"><button  type="submit" value="Send">Send Message</button></div>
            </form>
              <div v-if="testButClicked" class="alert alert-success" role="alert">Submitted Successfully</div>
          </div>  

        </div>

      </div>
    </section>
</template>
<script>
import emailjs from 'emailjs-com'

export default {
   el: "#exceljutacontact",
      data() {
         return {
            testButClicked: false
          }
      },
        methods: {
          sendEmail(e) {
            this.testButClicked = true;
            emailjs.sendForm('service_f14gmw6', 'template_Exceljuta', e.target, 'user_9Av8DvIJO7GfE0tuKccZX')
              .then((result) => {
                // alert("Successful Message");
                  window.location.reload()
                  console.log('SUCCESS!', result.status, result.text);
              }, (error) => {
                  console.log('FAILED...', error);
              });
          }
        }, 

         watch:{
              testButClicked(val){
                 if (val===true){
                    setTimeout(()=>this.testButClicked=false,8000);
                  }
                }
            }
}
</script>
<style>
#exceljutacontact {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}

.alert {
  background-color: lightgreen;
  padding: 15px;
  margin-top:20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.contact {
  padding:140px 0px 40px 0px ;
  background: url("../assets/images/background/about-bg.png") left center no-repeat;
  position: relative;
}

.contact:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.contact .container {
  position: relative;
}

.contact .info {
  width: 100%;
}

.contact .info i {
  font-size: 20px;
  background: #0880e8;
  color: #fff;
  float: left;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h6 {
  padding: 10px 0 0 60px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #043c6d;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #444444;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  border: 0;
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #0880e8;
  color: #fff;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>